/*Constants*/
:root {
  --brand-color: #ff0000;
  --primary-color: #444444; /*GREY-F-100 Headers*/
  --secondary-color: #777777; /*GREY-E-100 Paragraphs*/
  --bg-color: #ffffff; /*GREY-A-100 Background*/
  --btn-color: #eb0a0a; /*RED-B-100 Buttons and interactions*/
  --line-color: #bfc0bf; /*GREY-D-100 Lines and borders*/
  --main-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* --main-font: sans-serif; */
  --breakpoint-tablet: 768px;
  --breakpoint-laptop: 1280px;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueLTPro-Roman.eot");
  src: url("../fonts/HelveticaNeueLTPro-Roman.eot") format("embedded-opentype"),
    url("../fonts/HelveticaNeueLTPro-Roman.woff") format("woff"),
    url("../fonts/HelveticaNeueLTPro-Roman.ttf") format("truetype"),
    url("../fonts/HelveticaNeueLTPro-Roman.svg") format("svg");
}

/*Box sizing Paul Irish*/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/*General*/
* {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
p {
  font-family: var(--main-font);
}

h1 {
  font-size: 1.85rem;
  line-height: 2.0625rem;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
  font-weight: 400;
  color: var(--primary-color);
}

h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: center;
  color: var(--primary-color);
  margin: 32px 0;
}

h3 {
  text-align: center;
  margin-bottom: 15px;
}

p {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  color: var(--secondary-color);
}

button {
  padding: 0 24px;
  height: 36px;
  font-family: var(--main-font);
  outline: none;
  font-size: 0.875rem;
  border-radius: 4px;
  cursor: pointer;
  min-width: 76px;
  margin: 20px;
  transition: all 0.3s ease-in;
  font-weight: 500;
  border: none;
  line-height: 36px;
}

.btn-primary {
  background-color: var(--btn-color);
  border: 1px solid var(--btn-color);
  color: var(--bg-color);
}

.btn-primary:hover {
  box-shadow: 0px, 16px, 18px, var(--primary-color);
  transition: box-shadow 0.3s ease-in;
}

.btn-tertiary {
  border: 1px solid var(--secondary-color);
  background-color: var(--bg-color);
}

.btn-tertiary:hover {
  border: none;
  background-color: var(--primary-color);
  color: var(--bg-color);
  transition: all 0.3s ease-in;
}

.btn-disabled {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--bg-color);
  cursor: default;
}

.container {
  width: 100%;
  padding: 0 24px;
}

@media (min-width: 768px) {
  button {
    font-size: 1rem;
    height: 48px;
    min-width: 80px;
  }
  h1 {
    font-size: 2em;
    margin-top: 72px;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.625rem;
  }
}

@media (min-width: 1280px) {
  h1 {
    font-size: 2.3em;
    margin-top: 72px;
  }

  h2 {
    font-size: 1.5em;
  }
  .container {
    padding: 0 40px;
  }
}

p.detail {
  font-weight: 700;
  font-size: 0.75rem;
}

.red-font {
  color: var(--brand-color);
}

.bold {
  font-weight: bold;
}

/*Header*/

header {
  width: 100%;
  border-bottom: 3px solid var(--brand-color);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-content {
  width: 100%;
  margin: 0 auto;
  min-height: 10vh;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
}

.logo a img {
  width: 114px;
}

@media (min-width: 768px) {
  .logo a img {
    width: 220px;
  }
}

@media (min-width: 1280px) {
  .header-content {
    width: 1200px;
  }
}

/*Overlay*/

.overlay {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-container {
  background-color: var(--bg-color);
  width: 100%;
  height: 100vh;
  padding: 15px;
  position: relative;
  overflow-y: scroll;
}

.exit-cross {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.exit-cross::after {
  content: "";
  width: 24px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: var(--secondary-color);
  transform: rotate(-45deg);
}

.exit-cross::before {
  content: "";
  width: 24px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: var(--secondary-color);
  transform: rotate(45deg);
}

@media (min-width: 768px) {
  .exit-cross {
    width: 36px;
    height: 36px;
  }

  .exit-cross::after {
    width: 36px;
    height: 2px;
  }

  .exit-cross::before {
    width: 36px;
    height: 2px;
  }
}

.overlay-content {
  width: 95%;
  margin: 0 auto;
}

.overlay-content p {
  padding: 5px 0;
}

.overlay-content p span {
  font-weight: bold;
}

@media (min-width: 768px) {
  .overlay-container {
    width: 80%;
    height: 75vh;
  }
  .overlay-content {
    width: 90%;
  }
}

@media (min-width: 1280px) {
  .overlay-container {
    width: 70%;
  }
  .overlay-content {
    width: 80%;
  }
}

/*Scrollbar overlay*/

.overlay-container::-webkit-scrollbar {
  width: 10px;
}

.overlay-container::-webkit-scrollbar-track {
  border-radius: 5px;
}

.overlay-container::-webkit-scrollbar-thumb {
  background-color: var(--line-color);
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

/*Animations*/

.up-animation {
  animation: popup-animation 0.5s ease-out forwards;
}

.down-animation {
  animation: popdown-animation 0.5s ease-out forwards;
}

@keyframes popup-animation {
  0% {
    top: 100%;
    opacity: 0;
  }

  5% {
    top: 50%;
    opacity: 0;
  }

  100% {
    top: 0%;
    opacity: 1;
  }
}

@keyframes popdown-animation {
  0% {
    top: 0%;
    opacity: 1;
  }

  95% {
    top: 50%;
    opacity: 0;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}

.appear {
  animation: appear-animation 0.3s linear forwards;
}

.disappear {
  animation: disappear-animation 0.3s linear forwards;
}

@keyframes appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.result-enter {
  animation: enter 0.5s ease forwards;
}

@keyframes enter {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*Inputs*/

.slider-area,
.select-area,
.radio-area {
  width: 100%;
  margin: 40px 0;
}

.radio-area {
  margin: 50px 0;
}

select {
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  display: flex;
}

.input-field {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
}

.input-field input,
.input-field select,
.input-field label {
  font-family: var(--main-font);
  color: var(--secondary-color);
  font-size: 1.25rem;
  text-align: center;
}

.input-field input,
.input-field select {
  margin: 10px 15px;
  background-color: transparent;
  min-height: 48px;
  outline: none;
  border: 1px solid var(--line-color);
  border-radius: 4px;
  font-size: 1.8rem;
  width: 50%;
  padding: 10px 0;
}

.input-field .radio-options {
  margin: 10px 0;
  min-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

p.radio-option {
  font-size: 1.8em;
  color: var(--line-color);
  font-weight: 800;
  cursor: pointer;
  margin: 10px;
  transition: all 0.5s ease;
}

p.radio-option:hover {
  color: black;
  transition: all 0.5s ease;
}

p.selected-option {
  color: black;
}

p.type-of-consultant {
  width: 100%;
  min-height: 50px;
  text-align: center;
  font-size: 1.5rem;
  margin: 10px 0;
  padding: 10px 0;
}

@media (min-width: 768px) {
  .input-field {
    flex-direction: row;
  }

  .input-field label {
    width: 60%;
    text-align: left;
    margin-bottom: 0;
    padding-right: 15px;
    font-size: 1.4rem;
  }

  .input-field input,
  .input-field select {
    width: 40%;
    font-size: 2rem;
    margin: 10px 0;
  }

  .input-field .radio-options {
    width: 40%;
  }

  p.radio-option {
    font-size: 2rem;
  }

  p.type-of-consultant {
    width: 40%;
  }
}

/*info popup*/

.input-field .info-popup {
  position: absolute;
  top: -16px;
  right: 15px;
  border: 1px solid var(--primary-color);
  border-radius: 7px;
}

@media (min-width: 768px) {
  .input-field .info-popup {
    top: -18px;
  }
}

.input-field .info-popup .info-icon {
  height: 15px;
  padding: 5px;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  flex-direction: row;
  cursor: pointer;
  font-weight: bold;
  color: var(--primary-color);
}

.input-field .info-popup .content-popup {
  position: absolute;
  background-color: var(--bg-color);
  right: 150%;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid var(--line-color);
  transition: opacity 0.5s ease;
  cursor: initial;
  min-width: 250px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  padding: 10px 0;
  z-index: 20;
}

.input-field .info-popup .content-popup::before {
  content: "";
  background-color: var(--bg-color);
  width: 7px;
  height: 7px;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 1px solid var(--line-color);
  border-right: 1px solid var(--line-color);
}

.range-area {
  width: 100%;
}

.slider-labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-labels p {
  font-size: 1.2rem;
}

input.slider {
  border: none;
  border-radius: 10px;
  height: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  margin: 10px 0;
}

input.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 10px;
  border-radius: 10px;
  transition: background 450ms ease-in;
}

input.slider::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  height: 15px;
  transform: translateY(-3px);
  cursor: pointer;
  background: var(--bg-color);
  border: 1px solid var(--secondary-color);
  border-radius: 7px;
}

/** FF*/
/*
input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}
input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}
*/
/* IE*/
/*
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}
input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}
*/

/*Team selector*/
.team-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-selector select,
.team-selector label {
  font-family: var(--main-font);
  color: var(--secondary-color);
  font-size: 1.1rem;
  padding: 10px;
  outline: none;
  border-radius: 4px;
}

.team-selector label {
  padding: 0;
  margin-right: 15px;
}

.team-selector select {
  padding: 10px 30px;
  text-align-last: left;
}

@media (min-width: 768px) {
  .team-selector {
    justify-content: flex-start;
  }

  .team-selector select,
  .team-selector label {
    font-size: 1.25rem;
  }
}

/*Main content*/

main {
  width: 90%;
  margin: 30px auto;
}

@media (min-width: 768px) {
  main {
    width: 85%;
  }
}

@media (min-width: 1280px) {
  main {
    width: 70%;
    min-height: 60vh;
  }
}

main h2 span {
  cursor: pointer;
  color: var(--secondary-color);
  transition: color 0.5s ease;
}

section.stages-container {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
  margin-bottom: 40px;
}

.stages-wrapper {
  width: 300%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(calc(var(--i) * -33.33%));
  transition: all 0.7s ease;
}

.stage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputs-container {
  width: 100%;
}

@media (min-width: 768px) {
  .inputs-container {
    width: 90%;
  }
}

@media (min-width: 1480px) {
  .inputs-container {
    width: 70%;
  }
}

@media (min-width: 1650px) {
  .inputs-container {
    width: 60%;
  }
}

/*Stage Markers*/
.stage-marker {
  height: 20px;
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage-marker .marker {
  width: 60px;
  height: 3px;
  margin: 0 25px;
  border-radius: 2px;
  background-color: var(--line-color);
  transition: all 0.3s ease;
}

.stage-marker .marker.active-marker {
  background-color: var(--brand-color);
  transition: all 0.3s ease;
}

/*Footer*/

footer {
  height: 10vh;
  border-top: 1px solid var(--line-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

footer img {
  width: 65px;
}

/*Table*/
table {
  font-family: var(--main-font);
  text-align: left;
  border-collapse: collapse;
}

table tbody tr td,
table tfoot tr td {
  border-bottom: 1px solid var(--line-color);
  padding: 10px 0;
  width: 50%;
}

table tbody tr td:nth-last-child(1),
table tfoot tr td:nth-last-child(1) {
  text-align: end;
  padding-right: 10px;
  width: 50%;
}

table tbody tr td:first-child,
table tfoot tr td:first-child {
  padding-left: 10px;
}

@media (min-width: 768px) {
  table tbody tr td:first-child,
  table tfoot tr td:first-child {
    padding-left: 20px;
  }

  table tbody tr td:nth-last-child(1),
  table tfoot tr td:nth-last-child(1) {
    padding-right: 20px;
  }
}

/*Summary*/

.summary-table {
  width: 90%;
  margin: 30px auto;
  font-size: 1.1em;
}

@media (min-width: 768px) {
  .summary-table {
    width: 70%;
  }
}

@media (min-width: 1480px) {
  .summary-table {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .complete-results section {
    width: 70%;
  }

  .summary-table {
    font-size: 1.25em;
  }
}

/*Results*/
div.results {
  background-color: var(--bg-color);
  width: 100%;
  min-height: 50vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.results-container {
  width: 90%;
}

.results-container button {
  margin: 0 auto;
  display: block;
}

.results-content {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 420px;
}

.funnel {
  width: 100%;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .results-container {
    width: 80%;
  }

  .results-content {
    flex-direction: row;
  }

  .funnel {
    width: 45%;
  }
}

.funnel table {
  font-family: var(--main-font);
  font-size: 1.1rem;
  width: 100%;
}

.funnel table tfoot {
  font-weight: bold;
}

/*spinner*/
.spinner {
  width: 300px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 25px;
}

.spinner > img {
  width: 100px;
  animation: spinner-animation 2s ease infinite;
  margin-bottom: 20px;
}

@keyframes spinner-animation {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(200px) rotateY(360deg);
  }
}

/*pdf printer*/
img.pdf-printer-btn {
  width: 15px;
  cursor: pointer;
  margin-left: 2px;
}

.pdf-printed {
  page-break-after: avoid;
}

.pdf-funnel-container {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.pdf-funnel-container .funnel {
  width: 40%;
}
